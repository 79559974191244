var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h1', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v("ADMIN PAGE")])]), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Lupa Password? 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Masukan Email Kamu yang Telah Didaftarkan untuk Mengatur Ulang Kata Sandi ")]), _c('validation-observer', {
    ref: "forgotPassword"
  }, [_c('b-form', {
    staticClass: "auth-forgot-password-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "forgot-password-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "forgot-password-email",
            "state": errors.length > 0 ? false : null,
            "name": "forgot-password-email",
            "placeholder": "Masukkan email"
          },
          model: {
            value: _vm.userEmail,
            callback: function callback($$v) {
              _vm.userEmail = $$v;
            },
            expression: "userEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    }
  }, [_vm._v(" Kirim ")])], 1)], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'admin-login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Kembali login ")], 1)], 1)], 1), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "id": "modal-scoped",
      "centered": "",
      "title": "Using Component Methods"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('h5', [_vm._v("Verifikasi email")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('h4', [_vm._v(_vm._s(_vm.message))])];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref4) {
        var ok = _ref4.ok,
            cancel = _ref4.cancel,
            hide = _ref4.hide;
        return [_c('b-button', {
          attrs: {
            "size": "lg",
            "block": "",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.visitEmail();
            }
          }
        }, [_vm._v(" Lihat Email ")])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }